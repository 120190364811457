import { IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import { observer } from "mobx-react";
import React from "react";

export type HeaderProps = {};

const Header: React.ComponentType<HeaderProps> = observer(() => {
    return (
        <IonHeader>
            <IonToolbar>
                <IonTitle size="large">
                    <div className="flex justify-center w-full">
                        <span className="font-hairline text-2xl md:text-5xl text-fuchsia-700">
                            Transport For Change
                        </span>
                    </div>
                </IonTitle>
            </IonToolbar>
        </IonHeader>
    );
});

export default Header;
