import React from "react";

export type LabelType = "standard" | "error";

export type LabelProps = {
    labelText: string;
    identifier?: string;
    labelType?: LabelType;
};

const Label: React.ComponentType<LabelProps> = ({ labelText, identifier, labelType = "standard" }) => {
    return (
        <label
            htmlFor={identifier}
            className={`pl-1 block font-medium ${
                labelType === "standard"
                    ? "text-slate-700 pt-4 text-sm"
                    : "text-red-400 pt-1 text-xs"
            }`}
        >
            {labelText}
        </label>
    );
};

export default Label;
