import React from "react";

export type ButtonProps = {
    text: string;
    onClick?: () => void;
};

const Button: React.ComponentType<ButtonProps> = ({ text, onClick }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700
              hover:bg-gradient-to-br focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg
              shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-sm px-5
              py-2.5 text-center mr-2 mb-2 w-full"
        >
            {text}
        </button>
    );
};

export default Button;
