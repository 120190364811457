import React from "react";
import { createHome } from "../../pages/home/install";
import Layout from "./layout";

export type CreateLayoutArgs = {};

const Home = createHome();

const createLayout = (args: CreateLayoutArgs) => {
    return () => <Layout Home={Home} />;
};

export { createLayout };
