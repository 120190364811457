import React, { ChangeEvent } from "react";
import Spinner from "../spinner/spinner";

export type InputProps = {
    onChange?: (input: string) => void;
    value?: string;
    placeholder: string;
    loading?: boolean;
    labelledBy?: string;
    onBlur?: () => void;
    onFocus?: () => void;
};

const Input: React.ComponentType<InputProps> = (
    { placeholder, labelledBy, onChange, value, onBlur, onFocus, loading },
) => {
    const handleChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.target.value);
    }, [onChange]);

    return (
        <div>
            <input
                aria-labelledby={labelledBy}
                type="text"
                className="relative placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-4 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            <div className="absolute right-1 bottom-2.5">
                {loading ? <Spinner /> : null}
            </div>
        </div>
    );
};

export default Input;
