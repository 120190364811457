import axios, { AxiosRequestConfig } from "axios";
import fake_geo_data from "./fake_geo_data";

const API_KEY = "d7b83b18f99146a78a30f27554bd90cd";

const config = (text: string): AxiosRequestConfig => ({
    method: "get",
    url: `https://api.geoapify.com/v1/geocode/autocomplete?text=${text}&bias=countrycode:au&apiKey=${API_KEY}&format=json`,
    headers: {},
});

export interface GeoLocationService {
    autoComplete(input: string): Promise<Address[]>;
}

export interface Result {
    results?: Address[];
}

export interface Address {
    country_code: string;
    housenumber: string;
    street: string;
    country: string;
    county: string;
    datasource: Datasource;
    postcode: string;
    state: string;
    district: string;
    city: string;
    lon: number;
    lat: number;
    state_code: string;
    formatted: string;
    address_line1: string;
    address_line2: string;
    result_type: string;
    rank: Rank;
    place_id: string;
}

export interface Datasource {
    sourcename: string;
    attribution: string;
    license: string;
}

export interface Rank {
    confidence: number;
    match_type: string;
}

export const addressToString = (address: Address) => {
    return address.formatted;
};

export class GeoApifyGeoLocationService implements GeoLocationService {
    autoComplete = async (input: string): Promise<Address[]> => {
        if (!input || input.length === 0) {
            return [];
        }

        const res = await axios.request<Result>(config(input));

        console.log(res.data);

        return res.data.results ?? [];
    };
}

export class FakeGeoLocationService implements GeoLocationService {
    autoComplete(input: string): Promise<Address[]> {
        if (!input || input.length === 0) {
            return Promise.resolve([]);
        }
        return Promise.resolve(fake_geo_data);
    }
}
