import { Transition } from "@headlessui/react";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import Input from "../input/input";

export type AddressProps = {
    onChange: (input: string) => void;
    value?: string;
    labelledBy?: string;
    loading: boolean;
    onAddressClicked: (address: string) => void;
    maybeAddresses: string[];
    showMenu?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
};

const MaybeAddress = ({ address, onClick }: { address: string; onClick: (address: string) => void }) => {
    const handleClick = useCallback(() => {
        onClick(address);
    }, [onClick, address]);
    return (
        <li
            onClick={handleClick}
            className="block bg-white w-full first:pt-4 py-2 pl-4 pr-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
        >
            {address}
        </li>
    );
};

const Address: React.ComponentType<AddressProps> = observer(
    ({ labelledBy, onChange, value, showMenu, maybeAddresses, onFocus, onBlur, loading, onAddressClicked }) => {
        return (
            <div className="w-full flex flex-col relative">
                <Input
                    placeholder="Enter address..."
                    labelledBy={labelledBy}
                    onChange={onChange}
                    value={value}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    loading={loading}
                />
                <Transition show={showMenu}>
                    <ul className="w-full mt-1 z-40 absolute shadow-md rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        {maybeAddresses.map((address) => <MaybeAddress onClick={onAddressClicked} address={address} />)}
                    </ul>
                </Transition>
            </div>
        );
    },
);

export default Address;
