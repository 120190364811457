import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react";
import { createAddress } from "../../components/address/install";
import { createHeader } from "../../components/header/install";
import { GeoApifyGeoLocationService } from "../../service/geo_location/geo_location";
import Home from "./home";

class HomeStore {
    constructor() {
        makeAutoObservable(this);
    }

    fromAddress: string = "";
    toAddress: string = "";

    fromError: string = "";
    toError: string = "";

    setFromAddress = (address: string) => {
        runInAction(() => {
            this.fromAddress = address;
        });
    };

    setToAddress = (address: string) => {
        runInAction(() => {
            this.toAddress = address;
        });
    };

    setFromError = (error: string) => {
        runInAction(() => {
            this.fromError = error;
        });
    };

    setToError = (error: string) => {
        runInAction(() => {
            this.toError = error;
        });
    };
}

class HomePresenter {
    private readonly store: HomeStore;

    constructor(homeStore: HomeStore) {
        this.store = homeStore;
    }

    onFromUpdated = (address: string) => {
        this.store.setFromAddress(address);
        this.store.setFromError("");
    };

    onToUpdated = (address: string) => {
        this.store.setToAddress(address);
        this.store.setToError("");
    };

    onGoClicked = () => {
        const { toAddress } = this.store;
        const fromAddress = this.store.fromAddress;

        let valid = true;

        if (!fromAddress || fromAddress.length === 0) {
            this.store.setFromError("must be a valid address");
            valid = false;
        } else {
            this.store.setFromError("");
        }

        if (!toAddress || toAddress.length === 0) {
            this.store.setToError("must be a valid address");
            valid = false;
        } else {
            this.store.setToError("");
        }

        if (!valid) {
            return;
        }
    };
}

// const geoLocationService = new FakeGeoLocationService();
const geoLocationService = new GeoApifyGeoLocationService();
const Header = createHeader();
const homeStore = new HomeStore();
const homePresenter = new HomePresenter(homeStore);

const FromAddress = createAddress(geoLocationService, homePresenter.onFromUpdated);
const ToAddress = createAddress(geoLocationService, homePresenter.onToUpdated);

const createHome = () => {
    return observer(() => (
        <Home
            Header={Header}
            FromAddress={FromAddress}
            ToAddress={ToAddress}
            onGoClicked={homePresenter.onGoClicked}
            fromError={homeStore.fromError}
            toError={homeStore.toError}
        />
    ));
};

export { createHome };
