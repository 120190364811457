import { CreateAnimation, IonContent, IonPage } from "@ionic/react";
import { observer } from "mobx-react";
import React from "react";
import { InstalledAddressProps } from "../../components/address/install";
import Button from "../../components/button/button";
import Label from "../../components/label/label";
import Logo from "../../components/logo/logo";
import { useUniqueId } from "../../hooks/useUniqueId";

export type HomeProps = {
    Header: () => JSX.Element;
    FromAddress: (props: InstalledAddressProps) => JSX.Element;
    ToAddress: (props: InstalledAddressProps) => JSX.Element;
    onGoClicked?: () => void;
    fromError?: string;
    toError?: string;
};

const Home: React.ComponentType<HomeProps> = observer(
    ({ Header, FromAddress, ToAddress, onGoClicked, fromError, toError }) => {
        const fromLabel = useUniqueId();
        const toLabel = useUniqueId();
        return (
            <IonPage>
                <IonContent fullscreen>
                    <div className="w-full flex flex-col items-center">
                        <Logo />
                        <CreateAnimation
                            play={true}
                            delay={1000}
                            duration={1000}
                            iterations={1}
                            easing="ease-out"
                            fromTo={[
                                { property: "transform", fromValue: "translateY(-30px)", toValue: "translateY(0px)" },
                                { property: "opacity", fromValue: "0", toValue: "1" },
                            ]}
                        >
                            <div className="flex flex-col items-center">
                                <p className="pt-4 font-normal text-slate-100 text-3xl">Transport For Change</p>
                                <p className="pt-4 font-normal text-slate-100 text-md">
                                    Make smarter transport choices together
                                </p>
                            </div>
                        </CreateAnimation>
                    </div>
                    <form className="w-auto flex flex-col p-4 pb-4 m-4 mt-12 rounded-lg border border-slate-300 bg-slate-50">
                        <Label labelText="From" identifier={fromLabel} />
                        <FromAddress labelledBy={fromLabel} />
                        {fromError ? <Label labelText={fromError} labelType="error" /> : null}
                        <Label labelText="To" identifier={fromLabel} />
                        <ToAddress labelledBy={toLabel} />
                        {toError ? <Label labelText={toError} labelType="error" /> : null}
                        <div className="pt-6 w-24">
                            <Button text="Go" onClick={onGoClicked} />
                        </div>
                    </form>
                </IonContent>
            </IonPage>
        );
    },
);

export default Home;
