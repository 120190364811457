import { CreateAnimation } from "@ionic/react";
import React from "react";

const logoImagePath = "assets/icon/logo.svg";

const Logo = ({ animated = true }: { animated?: boolean }) => {
    if (animated) {
        return (
            <CreateAnimation
                play={true}
                delay={300}
                duration={700}
                iterations={1}
                easing="ease-out"
                fromTo={[
                    { property: "transform", fromValue: "translateY(-30px)", toValue: "translateY(0px)" },
                    { property: "opacity", fromValue: "0", toValue: "1" },
                ]}
            >
                <img
                    className="w-1/4 rounded-full m-auto mt-16 max-w-xs max-h-xs"
                    src={logoImagePath}
                    alt="t4c logo"
                />
            </CreateAnimation>
        );
    }
    return (
        <img
            className="w-1/4 rounded-full m-auto mt-16 max-w-xs max-h-xs"
            src={logoImagePath}
            alt="t4c logo"
        />
    );
};

export default Logo;
