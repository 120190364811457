import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { observer } from "mobx-react";
import React from "react";
import { Route } from "react-router-dom";

export type LayoutProps = {
    Home: () => JSX.Element;
};

const Layout: React.ComponentType<LayoutProps> = observer(({ Home }) => {
    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route exact path="/" component={Home} />
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
});

export default Layout;
