import { debounce } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import { addressToString, GeoLocationService } from "../../service/geo_location/geo_location";
import Address from "./address";

export type InstalledAddressProps = {
    labelledBy?: string;
};

class AddressStore {
    address: string = "";
    loading = false;
    dirty = true;

    maybeAddresses: string[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setAddress = (address: string) => {
        runInAction(() => {
            this.address = address;
        });
    };

    setMaybeAddresses = (addresses: string[]) => {
        runInAction(() => {
            this.maybeAddresses = addresses;
        });
    };

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        });
    };

    setDirty = (dirty: boolean) => {
        runInAction(() => {
            this.dirty = dirty;
        });
    };
}

class AddressPresenter {
    private readonly addressStore: AddressStore;
    private readonly geoLocationService: GeoLocationService;
    private readonly onAddressUpdated: (address: string) => void;

    constructor(
        addressStore: AddressStore,
        geoLocationService: GeoLocationService,
        onAddressUpdated: (address: string) => void,
    ) {
        this.addressStore = addressStore;
        this.geoLocationService = geoLocationService;
        this.onAddressUpdated = onAddressUpdated;
    }

    onAddressChange = (address: string) => {
        this.addressStore.setAddress(address);
        this.addressStore.setLoading(true);
        this.addressStore.setDirty(true);
        this.onAddressUpdated(""); // Communicates to the parent that the address is no longer 'clean'

        // Only call the geolocation service if the address length is more than 4 characters
        if (!address || address.length <= 4) {
            this.addressStore.setMaybeAddresses([]);
            this.addressStore.setLoading(false);
            return;
        }
        this.debounceSearch(address);
    };

    onAddressClick = (address: string) => {
        this.addressStore.setDirty(false);
        this.addressStore.setAddress(address);
        this.onAddressUpdated(address);
    };

    debounceSearch = debounce(async (address: string) => {
        const res = await this.geoLocationService.autoComplete(address);
        this.addressStore.setMaybeAddresses(res.map(address => addressToString(address)));
        this.addressStore.setLoading(false);
    }, 700);
}

const createAddress = (geoLocationService: GeoLocationService, onAddressUpdated: (address: string) => void) => {
    const addressStore = new AddressStore();
    const addressPresenter = new AddressPresenter(addressStore, geoLocationService, onAddressUpdated);
    return observer(({ labelledBy }: InstalledAddressProps) => {
        const [isFocus, setIsFocus] = useState(false);

        const setFocus = useCallback(() => {
            setIsFocus(true);
        }, [setIsFocus]);

        const setBlur = useCallback(() => {
            setIsFocus(false);
        }, [setIsFocus]);

        return (
            <Address
                labelledBy={labelledBy}
                onChange={addressPresenter.onAddressChange}
                loading={addressStore.loading}
                value={addressStore.address}
                onAddressClicked={addressPresenter.onAddressClick}
                showMenu={addressStore.dirty && addressStore.maybeAddresses.length > 0 && isFocus}
                maybeAddresses={addressStore.maybeAddresses}
                onFocus={setFocus}
                onBlur={setBlur}
            />
        );
    });
};

export { createAddress };
